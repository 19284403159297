<script setup lang="ts">
import SidebarSection from '@/components/Sidebar/SidebarSection.vue'
import TtAccordion from '@/components/TtAccordion.vue'
import TtButton from '@/components/TtButton.vue'
import TtTile from '@/components/TtTile.vue'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import TtRouteProfileInformation from '@/components/TtRouteProfileInformation.vue'
import Warning from '@/components/Icon/Warning.vue'

import { useMapsStore } from '@/store/maps'
import { useProfileStore } from '@/store/profile'
import { computed, ref, watch } from 'vue'
// import type { Geometry } from 'geojson'
import { format, addSeconds, parseISO } from 'date-fns'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const open = ref(true)
const profile = useProfileStore()

const mapStore = useMapsStore()
const { route, directions, currentRouteAlternative, routeCreatedAt } =
  storeToRefs(mapStore)

const routeDuration = computed(() => {
  if (!directions.value?.length) return ''

  const seconds = Math.floor(
    directions.value[currentRouteAlternative.value].duration
  )
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  if (hours > 0) {
    if (minutes - hours * 60 === 0) {
      return `${hours} ${hours === 1 ? 'timme' : 'timmar'}`
    } else {
      return `${hours} ${hours === 1 ? 'timme' : 'timmar'} och ${
        minutes - hours * 60
      } min.`
    }
  } else {
    return `${minutes} ${minutes === 1 ? 'minut' : 'minuter'}`
  }
})

const routeDistance = computed(() => {
  if (!directions.value?.length) return ''
  return `${Math.floor(
    directions.value[currentRouteAlternative.value].distance / 1000
  )}km`
})

const routeTime = () => {
  if (!routeCreatedAt.value || !directions.value?.length) return ''
  return `${format(routeCreatedAt.value, 'HH:mm')}-${format(
    addSeconds(
      routeCreatedAt.value,
      directions.value[currentRouteAlternative.value].duration
    ),
    'HH:mm'
  )}`
}

const legTime = (date: Date | null | undefined, seconds: number) => {
  if (!date) return ''
  return format(addSeconds(date, seconds), 'HH:mm')
}
</script>

<template>
  <div>
    <Sidebar>
      <SidebarSection>
        <TtAccordion v-if="route">
          <template v-slot:accordionButton>
            <div class="flex flex-1 justify-between">
              <div class="flex items-baseline">
                <h2 class="text-lg font-semibold">
                  {{ t('route.route') }}
                </h2>
                <div v-if="routeDuration !== ''" class="ml-3">
                  {{ routeDuration }}
                </div>
              </div>
              <!-- <TtBattery :percentage="54" /> -->
            </div>
          </template>
          <!-- <TtRoute /> -->
          <div class="mt-4">
            <ol class="space-y-1.5 text-sm">
              <li class="relative" v-for="(place, index) in route">
                <TtTile
                  :active="false"
                  :primaryText="place.place_name"
                  :secondaryText="
                    routeDuration !== '' && index === 0
                      ? legTime(routeCreatedAt, 0)
                      : routeDuration !== '' && directions?.[currentRouteAlternative].legs[index - 1]
                      ? legTime(routeCreatedAt, directions?.[currentRouteAlternative].legs.reduce((acc: number, curr: any, i: number) => {
                            if (i > index - 1) return acc
                            return acc + curr.duration
                        }, 0))
                      : ''
                  "
                />
                <!-- <div
                  v-if="store.directions.legs[index]"
                  class="absolute bottom-0 left-1/2 z-10 flex h-8 w-8 translate-y-5 -translate-x-1/2 items-center justify-center rounded-full border border-white/20 bg-tt-gray"
                >
                  <span class="sr-only">Hinder längs vägen</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div> -->
              </li>
            </ol>
          </div>
        </TtAccordion>
        <div v-if="route" class="mt-6 flex items-center justify-between">
          <div class="flex gap-1">
            <div>{{ routeDuration }} </div>
            <div v-if="routeDuration != ''">körtid</div>
          </div>
          <div>{{ routeTime() }}</div>
        </div>
        <div class="mt-6 pt-9 border-t border-tt-light-gray/50 space-y-3">
          <TtButton
            :as="'router-link'"
            to="/route/new"
            :size="'large'"
            :type="route ? 'secondary' : 'primary'"
          >
            <template v-if="!route">{{ t('route.createRoute') }}</template>
            <template v-else>{{ t('route.adjustRoute') }}</template>
          </TtButton>
          <TtButton
            v-if="route"
            :as="'router-link'"
            to="/"
            :size="'large'"
            :type="'primary'"
            @click.prevent="mapStore.clearRoute()"
          >
            {{ t('route.cancelRoute') }}
          </TtButton>
        </div>
        <TtRouteProfileInformation />
      </SidebarSection>
      <div class="m-5 mt-6 rounded border border-tt-pale/25 text-center items-center justify-center flex flex-col">
        <Warning class="my-4"></Warning>
        <p
        class="mx-6 mb-6"
        >
        {{ t('route.warning') }}
        </p>
      </div>
    </Sidebar>
  </div>
</template>

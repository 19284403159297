<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { useI18n } from 'vue-i18n'

import IconToilet from '@/components/Icon/Toilet.vue'
import IconToiletSeparate from '@/components/Icon/ToiletSeparate.vue'
import IconShower from '@/components/Icon/Shower.vue'
import IconWifi from '@/components/Icon/Wifi.vue'
import IconVatten from '@/components/Icon/Vatten.vue'
import IconEl from '@/components/Icon/El.vue'
import IconSoptunna from '@/components/Icon/Soptunna.vue'
import type { LocationFeature } from '@/location'

const { t } = useI18n({ useScope: 'global' })

const props = defineProps<{
  modelValue?: boolean
  icon?: string
  item: any
  onCheck?: (feature: LocationFeature) => void
}>()

const value = ref<boolean>(false)

const selectOptions = ref<any[]>([
  { text: '0', value: '0' },
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10+', value: '10+' }
])

const checked = computed({
  get: () => props.modelValue ?? value.value,
  set: (v) => {
    value.value = v
    emit('change', v)
    emit('update:modelValue', v)
  }
})

const emit = defineEmits<{
  (event: 'change', value: boolean): void
  (event: 'update:modelValue', value: boolean): void
}>()

const uncheckChildren = () => {
  props.item.children?.forEach((child: any) => {
    child.checked = false
    child.value = child.defaultValue || null
  })
}

const icon = (name: string) => {
  switch (name) {
    case 'toilet':
      return IconToilet
    case 'toilet-separate':
      return IconToiletSeparate
    case 'shower':
      return IconShower
    case 'wifi':
      return IconWifi
    case 'el':
      return IconEl
    case 'vatten':
      return IconVatten
    case 'soptunna':
      return IconSoptunna
    case 'resting-area':
      return IconRestingArea
    default:
      return ''
  }
}
</script>

<template>
  <Disclosure :defaultOpen="false" v-slot="{ open, close }">
    <DisclosureButton as="button" class="relative flex w-full items-center">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center"
      ></div>
      <slot name="accordionButton">
        <label
          class="group mb-1 flex flex-1 cursor-pointer items-center justify-between overflow-hidden rounded border border-black/10 px-4 py-3.5 focus-within:border-tt-green"
        >
          <input
            v-model="checked"
            style="filter: invert(1)"
            class="peer form-checkbox mr-2 h-4.5 w-4.5 cursor-pointer rounded border border-[#201f1f] bg-transparent text-[#FD1E5A] transition-colors focus:border-[#FD1E5A] focus:outline-none focus:ring-0"
            type="checkbox"
            @change="uncheckChildren, onCheck && onCheck(item)"
          />

          <div
            class="relative z-10 flex flex-1 items-center justify-between font-normal tracking-tight peer-checked:text-tt-gray"
          >
            <div class="flex-1 text-left">
              {{ item.name }}
            </div>
            <div class="flex justify-between">
              <div
                v-if="item.icon"
                class="flex h-6 w-6 items-center justify-center"
              >
                <component :is="icon(item.icon as string)" />
              </div>
              <div
                v-if="
                  item.key === 'truck-15' ||
                  item.key === 'truck-22' ||
                  item.key === 'truck-24' ||
                  item.key === 'truck-34'
                "
              >
                <span class="pr-1.5 text-right font-mono text-base">
                  {{
                    item.key === 'truck-15'
                      ? '<15'
                      : item.key === 'truck-22'
                      ? '<22'
                      : item.key === 'truck-24'
                      ? '<24'
                      : item.key === 'truck-34'
                      ? '<34'
                      : ''
                  }}
                </span>
                <span class="font-mono text-tt-gray/60">{{ 'm' }}</span>
              </div>
            </div>
          </div>
        </label>
      </slot>
    </DisclosureButton>
    <div v-show="checked" class="pl-[19px]">
      <DisclosurePanel static>
        <label
          v-for="child in item.children"
          class="group mb-1 flex flex-1 cursor-pointer items-center justify-between overflow-hidden rounded border border-black/10 px-4 py-3.5 focus-within:border-tt-green"
        >
          <input
            v-model="child.checked"
            style="filter: invert(1)"
            class="peer form-checkbox mr-2 h-4.5 w-4.5 cursor-pointer rounded border border-[#201f1f] bg-transparent text-[#FD1E5A] transition-colors focus:border-[#FD1E5A] focus:outline-none focus:ring-0"
            type="checkbox"
            @change="item.onCheck && item.onCheck(item, child)"
          />

          <div
            class="relative z-10 flex flex-1 items-center justify-between font-normal tracking-tight peer-checked:text-tt-gray"
          >
            <div class="flex-1 whitespace-nowrap text-left">
              {{ child.name }}
            </div>
            <div>
              <div
                v-if="child.type === 'icon'"
                class="flex h-6 w-6 items-center justify-center"
              >
                <component :is="icon(child.icon as string)" />
              </div>
              <div
                v-if="child.type === 'price'"
                class="relative inset-y-0 right-0 flex gap-1.5"
              >
                <input
                  type="number"
                  min="0"
                  placeholder="0"
                  v-model="child.value"
                  class="form-input w-14 border-none border-transparent p-0 pr-5 text-right font-mono text-base focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                />
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3"
                >
                  <span class="font-mono text-tt-gray/60">{{ 'kr' }}</span>
                </span>
              </div>
              <div
                v-if="child.type === 'amount'"
                class="relative inset-y-0 right-0 flex items-center gap-1.5"
              >
                <label :for="child.key" class="sr-only">{{
                  t('location.amount') + ' ' + child.name
                }}</label>
                <select
                  :id="child.key"
                  :name="child.key"
                  v-model="child.value"
                  class="-mr-3 h-full cursor-pointer rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-right font-mono text-base focus:ring-0"
                >
                  <option
                    v-for="option in selectOptions"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div
                v-if="child.type === 'number'"
                class="relative inset-y-0 right-0 flex gap-1.5"
              >
                <input
                  type="number"
                  min="0"
                  placeholder="0000"
                  v-model="child.value"
                  class="form-input mr-0 w-14 border-none border-transparent p-0 text-right font-mono text-base focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                />
              </div>
              <div
                v-if="child.type === 'time'"
                class="relative inset-y-0 right-0 flex gap-1.5"
              >
                <input
                  type="time"
                  v-model="child.value"
                  class="form-input mr-0 border-none border-transparent p-0 text-right font-mono text-base focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                />
              </div>
            </div>
          </div>
        </label>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>

import type {
  Location,
  LocationFeatures,
  LocationFeature,
  LocationFeatureChild
} from '@/location'

import { i18n } from '@/i18n'
import Icon from '@/components/Icon.vue'

export function formattedAddress(location: Location) {
  if (!location?.address) return ''
  return `${location.address.street ?? ''}${
    location.address.zip || location.address.city ? ', ' : ''
  }${location.address.zip ? location.address.zip : ''}${
    location.address.zip && location.address.city ? ' ' : ''
  }${location.address.city ?? ''}${
    location.address.country ? ', ' + location.address.country : ''
  }`
}

/*
export const locationTypes = [
  {
    label: i18n.global.t('location.types.free_parking'),
    value: 'free_parking'
  },
  {
    label: i18n.global.t('location.types.paid_parking'),
    value: 'paid_parking'
  },
  {
    label: i18n.global.t('location.types.safe_parking'),
    value: 'safe_parking'
  },
  {
    label: i18n.global.t('location.types.ranking_position'),
    value: 'ranking_position'
  },
  {
    label: i18n.global.t('location.types.repair_shop'),
    value: 'repair_shop'
  },
  {
    label: i18n.global.t('location.types.toilet'),
    value: 'toilet'
  },
  {
    label: i18n.global.t('location.types.shower'),
    value: 'shower'
  },
  {
    label: i18n.global.t('location.types.store'),
    value: 'store'
  },
  {
    label: i18n.global.t('location.types.laundry'),
    value: 'laundry'
  },
  {
    label: i18n.global.t('location.types.restaurant'),
    value: 'restaurant'
  },
  {
    label: i18n.global.t('location.types.loadingUnloading'),
    value: 'loadingUnloading'
  }
]
*/

export const locationTypes = [
  {
    label: i18n.global.t('location.type'),
    value: 'parking'
  },
  {
    label: i18n.global.t('location.facility'),
    value: 'facility'
  },
  {
    label: i18n.global.t('filter.label.service'),
    value: 'service'
  },
  {
    label: i18n.global.t('filter.places.delivery'),
    value: 'loadingUnloading'
  }
]

const openingHoursOnCheck = (
  feature: LocationFeature,
  child: LocationFeatureChild
) => {
  if (!feature.children) return
  if (child.key === '24h') {
    for (const c of feature.children) {
      if (child.key !== c.key) {
        c.checked = false
      }
    }
  } else {
    for (const c of feature.children) {
      if (c.key === '24h') {
        c.checked = false
      } else {
        c.checked = true
      }
    }
  }
}

export const features = {
  openhours: {
    name: i18n.global.t('meta.label.openhours'),
    onCheck: (feature: LocationFeature) => {
      if (feature.key === 'allweek') {
        for (const child of features.openhours.children) {
          if (child.key !== feature.key) {
            child.checked = false
          }
        }
      } else {
        for (const child of features.openhours.children) {
          if (child.key === 'allweek') {
            child.checked = false
          }
        }
      }
    },
    children: [
      {
        name: i18n.global.t('location.features.allweek'),
        key: 'allweek',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.monday'),
        key: 'monday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.tuesday'),
        key: 'tuesday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.wednesday'),
        key: 'wednesday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.thursday'),
        key: 'thursday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.friday'),
        key: 'friday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.saturday'),
        key: 'saturday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.sunday'),
        key: 'sunday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      }
    ]
  },
  comforts: {
    name: i18n.global.t('filter.label.facilities'),
    children: [
      {
        name: i18n.global.t('location.features.toilet'),
        key: 'toilet',
        icon: 'toilet',
        checked: false,
        children: [
          {
            name: i18n.global.t('location.features.separate_toilet'),
            key: 'separate_toilet',
            type: 'icon',
            icon: 'separate_toilet',
            checked: false
          },
          // {
          //   name: i18n.global.t('location.features.amount_toilet_dames'),
          //   key: 'toilet-dames',
          //   type: 'amount',
          //   checked: false,
          //   value: '0',
          //   defaultValue: '0'
          // },
          // {
          //   name: i18n.global.t('location.features.amount_toilet_gents'),
          //   key: 'toilet-gents',
          //   type: 'amount',
          //   checked: false,
          //   value: '0',
          //   defaultValue: '0'
          // },
          // {
          //   name: i18n.global.t('location.features.price'),
          //   key: 'toilet-pay',
          //   type: 'price',
          //   checked: false,
          //   value: null
          // },
          {
            name: i18n.global.t('location.features.code'),
            key: 'toilet-code',
            type: 'number',
            checked: false,
            value: null
          }
        ]
      },
      {
        name: i18n.global.t('location.features.shower'),
        key: 'shower',
        checked: false,
        icon: 'shower'
      },
      {
        name: i18n.global.t('meta.wifi'),
        key: 'wifi',
        checked: false,
        icon: 'wifi'
      },
      {
        name: i18n.global.t('meta.el'),
        key: 'el',
        checked: false,
        icon: 'el'
      },
      {
        name: i18n.global.t('meta.vatten'),
        key: 'vatten',
        checked: false,
        icon: 'vatten'
      },
      {
        name: i18n.global.t('filter.facilities.kitchen'),
        key: 'kitchen',
        checked: false,
        icon: 'kitchen'
      },
      {
        name: i18n.global.t('filter.facilities.resting_area'),
        key: 'resting_area',
        checked: false,
        icon: 'resting_area'
      }
      // { name: 'Soptunna', key: 'soptunna', checked: false, icon: 'soptunna' }
    ]
  },
  services: {
    name: i18n.global.t('filter.label.service'),
    children: [
      {
        name: i18n.global.t('meta.restaurant'),
        key: 'restaurant',
        checked: false,
        icon: 'restaurant'
      },
      {
        name: i18n.global.t('meta.store'),
        key: 'store',
        checked: false,
        icon: 'store'
      },
      {
        name: i18n.global.t('meta.accommodation'),
        key: 'accommodation',
        checked: false,
        icon: 'accommodation',
        children: [
          {
            name: i18n.global.t('location.features.price'),
            key: 'accommodation_price',
            checked: false,
            type: 'price',
            value: null
          }
        ]
      },
      {
        name: i18n.global.t('meta.repair_shop'),
        key: 'repair_shop',
        checked: false,
        icon: 'repair_shop'
      },
      {
        name: i18n.global.t('meta.truck_cleaning'),
        key: 'truck_cleaning',
        checked: false
      }
    ]
  },
  parking_feature: {
    name: i18n.global.t('location.parking_features'),
    children: [
      {
        name: i18n.global.t('filter.places.ranking_position'),
        key: 'ranking_position',
        checked: false
      }
    ]
  },
  security: {
    name: i18n.global.t('filter.label.security'),
    children: [
      {
        name: i18n.global.t('filter.security.tt_premium_user_only'),
        key: 'tt_premium_user_only',
        checked: false,
        icon: 'my_locations'
      },
      {
        name: i18n.global.t('filter.security.adr_allowed'),
        key: 'adr_allowed',
        checked: false
      },
      {
        name: i18n.global.t('filter.security.guard.guard'),
        key: 'security_guard',
        checked: false
      },
      {
        name: i18n.global.t('filter.security.fence_locked_gate'),
        key: 'fence_locked_gate',
        checked: false
      },
      {
        name: i18n.global.t('filter.security.lighted'),
        key: 'light_sensor',
        checked: false
      },
      {
        name: i18n.global.t('filter.security.camera'),
        key: 'camera',
        checked: false
      },
      {
        name: i18n.global.t('filter.security.alarm'),
        key: 'alarm',
        checked: false
      }
    ]
  }
} as LocationFeatures

// accessibility: {
//   name: i18n.global.t('meta.label.accessibility'),
//   children: [
//     {
//       name: i18n.global.t('location.features.truck'),
//       key: 'truck-15',
//       checked: true
//     },
//     {
//       name: i18n.global.t('location.features.trailer_truck'),
//       key: 'truck-22',
//       checked: false
//     },
//     {
//       name: i18n.global.t('location.features.trailer_truck'),
//       key: 'truck-24',
//       checked: false
//     },
//     {
//       name: i18n.global.t('location.features.trailer_truck'),
//       key: 'truck-34',
//       checked: false
//     }
//   ]
// }

// export const parkingFeatures = [
//   {
//     name: i18n.global.t('filter.places.ranking_position'),
//     key: 'ranking_position',
//     checked: false
//   }
// ]

// export const securityFeatures = [
//   {
//     name: i18n.global.t('filter.security.tt_premium_user_only'),
//     key: 'tt_premium_user_only',
//     checked: false,
//     icon: 'my_locations'
//   },
//   {
//     name: i18n.global.t('filter.security.adr_allowed'),
//     key: 'adr_allowed',
//     checked: false
//   },
//   {
//     name: i18n.global.t('filter.security.guard.guard'),
//     key: 'security_guard',
//     checked: false
//   },
//   {
//     name: i18n.global.t('filter.security.fence_locked_gate'),
//     key: 'fence_locked_gate',
//     checked: false
//   },
//   {
//     name: i18n.global.t('filter.security.lighted'),
//     key: 'light_sensor',
//     checked: false
//   },
//   {
//     name: i18n.global.t('filter.security.camera'),
//     key: 'camera',
//     checked: false
//   },
//   {
//     name: i18n.global.t('filter.security.alarm'),
//     key: 'alarm',
//     checked: false
//   }
// ]

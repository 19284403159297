<script setup lang="ts">
import { computed } from 'vue'
import IconVerified from '../Icon/Verified.vue'
import IconClose from '../Icon/Close.vue'
import { useSidebarStore } from '@/store/sidebars'
import type { Location } from '@/location'
import ChevronLeft from '@/components/Icon/ChevronLeft.vue'

const props = defineProps<{
  location: Location
}>()

const { closeSidebar } = useSidebarStore()

const verified = computed(() => {
  return props.location?.meta?.some(
    (meta) => meta.type === 'trucktrust_verified' && meta.value === 'true'
  )
})

const locationImageSrc = computed(() => {
  if (props.location?.id) {
    return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
      props.location?.coordinates.lng
    },${props.location?.coordinates.lat},16,0/300x200?access_token=${
      import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    }`
  } else {
    return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${
      props.location?.coordinates.lng
    },${props.location?.coordinates.lat},16,0/300x200?access_token=${
      import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    }`
  }
})
</script>

<template>
  <div class="relative overflow-hidden">
    <div v-if="verified" class="absolute left-2 top-2 flex items-center gap-2">
      <IconVerified />
      <span class="font-sans text-base font-semibold text-white">
        Verified
      </span>
    </div>
    <img
      :src="locationImageSrc"
      class="-mb-6 h-[19rem] w-full object-cover"
      :alt="location?.name"
    />
    <div
      v-if="!location.id"
      class="absolute left-1/2 top-1/2 h-4 w-4 -translate-x-1/2 rounded-full border-[2px] border-white bg-tt-gray"
    ></div>
    <RouterLink
      class="absolute bottom-5 right-5 flex h-7 w-7 items-center justify-center rounded-[3px] bg-tt-gray text-white"
      to="/"
    >
      <span class="sr-only">Stäng</span>
      <ChevronLeft class="h-2.5 w-2.5" />
    </RouterLink>
  </div>
</template>

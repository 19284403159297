<script lang="ts" setup>
import { computed } from 'vue'
import TtAccordion from '@/components/TtAccordion.vue'
import TtMetaTile from '@/components/TtMetaTile.vue'
import ParkingSpace from '@/components/ParkingSpace.vue'
import IconParking from '@/components/Icon/ParkingPaid.vue'
import { useLocationStore } from '@/store/location'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

const { t } = useI18n({ useScope: 'global' })
const locations = useLocationStore()
const { location } = storeToRefs(locations)

interface MetaItem {
  type: string
  value: { key: string; assets?: any }
  assets?: any
}

const priceRange = computed(() => {
  const prices = location.value?.parkings?.map((parking) => parking.price) ?? []
  if (Math.min(...prices) === Math.max(...prices)) return Math.min(...prices)
  return `${Math.min(...prices)}-${Math.max(...prices)}`
})

const filteredMetaArray = (type: string) => {
  return location.value
    ? location.value.meta.filter((item: any) => item.type === type)
    : []
}

const filteredMeta = (type: string): any[] | undefined => {
  const filteredArray = filteredMetaArray(type) as MetaItem[] | undefined

  return filteredArray?.reduce((acc, curr) => {
    const { type, value, assets } = curr
    value.assets = assets

    if (!acc[type]) {
      acc[type] = { type, value: [value] }
    } else {
      if (!acc[type].value.includes(value)) {
        acc[type].value.push(value)
      }
    }
    return acc
  }, {} as Record<string, any>)?.[type]?.value
}
</script>

<template>
  <div
    v-if="location?.parkings?.length"
    class="mx-5 mb-5 border-b border-black/10 pb-5"
  >
    <TtAccordion>
      <template v-slot:accordionButton>
        <div class="flex w-full items-center justify-between font-light">
          <div>
            <h2 class="text-lg font-semibold leading-9 tracking-tight">
              {{ t('filter.places.parkings') }}
            </h2>
          </div>
          <div class="flex items-center gap-2 text-lg tracking-tight">
            <span>{{ priceRange }}{{ t('filter.places.cost_day') }}</span>
            <IconParking />
          </div>
        </div>
      </template>
      <div class="pt-3">
        <div class="grid gap-1.5">
          <ParkingSpace
            v-for="parking in location.parkings"
            :key="parking.id"
            :location="location"
            :parking="parking"
          />
        </div>
        <div v-if="filteredMeta('parking_feature')" class="mt-2">
          <h3 class="text-base font-semibold leading-9 tracking-tight">
            {{ t('location.parking_features') }}
          </h3>
          <div class="grid gap-1.5">
            <TtMetaTile
              v-for="(item, index) in filteredMeta('parking_feature')"
              :key="index"
              :item="item"
              :type="'meta'"
            />
          </div>
        </div>
        <div v-if="filteredMeta('security')" class="mt-2">
          <h3 class="text-base font-semibold leading-9 tracking-tight">
            {{ t('filter.label.security') }}
          </h3>
          <div class="grid gap-1.5">
            <TtMetaTile
              v-for="(item, index) in filteredMeta('security')"
              :key="index"
              :item="item"
              :type="'meta'"
            />
          </div>
        </div>
      </div>
    </TtAccordion>
  </div>
</template>

import type { User } from '@/account'

import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import axios from '@/axios'
import { isNative } from '@/helpers'
import { store } from '@/storehandler/tokens'
import { remove } from 'lodash'

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/user'
})

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

export enum LoginResponse {
  Success = 'success',
  TwoFactorRequired = '2fa-required',
  SiteOwnerNoMobile = 'site-owner-no-mobile',
  CompanyAdminNoMobile = 'company-admin-no-mobile',
  Unknown = 'unknown'
}

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    user: useStorage<User | null>('account.user', null, undefined, {
      serializer: jsonSerializer
    }),
    two_factor_required: false,
    login_form: {
      email: '',
      password: '',
      remember: false
    },
    firebase_id: '',
    favorites: [] as string[]
  }),
  getters: {
    isLoggedIn: (state) => !!state.user,
    isTrustedTrucker: (state): boolean => {
      const subscriptions = state.user?.subscriptions
      if (!subscriptions || subscriptions.length === 0) {
        return false
      }
      const trustedTruckerSubscriptions = subscriptions.filter(
        (subscription) =>
          subscription.product.type === 'trusted_trucker_subscription'
      )
      if (trustedTruckerSubscriptions.length === 0) {
        return false
      }
      const now = new Date()
      const activeSubscriptions = trustedTruckerSubscriptions.filter(
        (subscription) => {
          return (
            subscription.expires_at === null ||
            (new Date(subscription.expires_at) > now &&
              !subscription.deleted_at)
          )
        }
      )
      return activeSubscriptions.length > 0
    },
    TrustedTruckerSubscription: (state): boolean => {
      const subscriptions = state.user?.subscriptions
      if (!subscriptions || subscriptions.length === 0) {
        console.error(`subscription: ${subscriptions}`)
        return false
      }
      const trustedTruckerSubscriptions = subscriptions.filter(
        (subscription) =>
          subscription.product.type === 'trusted_trucker_subscription'
      )
      if (trustedTruckerSubscriptions.length === 0) {
        return false
      }
      return true
    },
    hasRole:
      (state) =>
      (role: string): boolean => {
        return !!state.user?.roles?.find((r) => r.type === role)
      }
  },
  actions: {
    async login({
      email,
      password,
      remember,
      trusted,
      code
    }: {
      email?: string
      password?: string
      remember?: boolean
      trusted?: boolean
      code?: string
    }): Promise<LoginResponse> {
      try {
        const response = await instance.post('/login', {
          email: email || this.login_form.email,
          password: password || this.login_form.password,
          remember: remember || this.login_form.remember,
          trusted,
          code
        })
        this.two_factor_required = response.data.two_factor === true

        if (this.two_factor_required && email && password) {
          this.login_form.email = email
          this.login_form.password = password
          this.login_form.remember = !!remember
        } else {
          this.login_form.email = ''
          this.login_form.password = ''
          this.login_form.remember = false

          const roles =
            response.data.user.roles?.map(
              (role: { type: string }) => role.type
            ) || []
          if (isNative) {
            if (roles.includes('siteowner')) {
              return LoginResponse.SiteOwnerNoMobile
            }
            if (roles.includes('companyadmin')) {
              return LoginResponse.CompanyAdminNoMobile
            }
          }
        }

        this.user = response.data.user
      } catch (error: unknown) {
        console.error(error)
        /* TODO: Handle error codes */
        return LoginResponse.Unknown
      }

      return this.two_factor_required
        ? LoginResponse.TwoFactorRequired
        : LoginResponse.Success
    },
    async verify(): Promise<boolean> {
      try {
        const resp = await instance.get('/verify')
        const ok = resp.status === 200

        if (ok && !this.user) {
          this.refreshUser() // Parent should catch any thrown errors
        }

        return ok
      } catch (error) {
        this.logout()
        console.error(error)
        return false
      }
    },
    async logout(): Promise<void> {
      this.user = null
      store.remove('tt-token')
    },
    async register(form: {
      email: string
      firstname: string
      lastname: string
      phone: string
      password: string
    }): Promise<string | boolean> {
      try {
        const response = await instance.post('/register', form)
        this.user = response.data.user
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return error.message as string
        }
        return false
      }

      return true
    },
    async forgotPassword(email: string): Promise<boolean> {
      return (await instance.post('/reset-password', { email }))?.status === 200
    },
    async resetPassword(
      email: string,
      password: string,
      token: string
    ): Promise<boolean> {
      return (
        (await instance.post('/reset-password', { email, password, token }))
          ?.status === 200
      )
    },
    async refreshUser(): Promise<boolean> {
      try {
        const response = await instance.get('/me')
        this.user = response.data
        return true
      } catch (error: unknown) {
        console.error(error)
        return false
      }
    },

    // 2FA
    async enroll2FA(): Promise<
      { secret: string; qrcode: string } | string | null
    > {
      try {
        const resp = await instance.post('/2fa/enroll', {})
        return resp.data
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return error.message as string
        }
      }
      return null
    },
    async enable2FA(form: {
      secret: string
      code: string
    }): Promise<boolean | string> {
      try {
        const resp = await instance.post('/2fa/enable', form)
        return resp.status === 200
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return error.message as string
        }

        return false
      }
    },
    setFirebaseID(id: string): void {
      this.firebase_id = id
    },
    setUser(user: User): void {
      this.user = user
    }
  }
})

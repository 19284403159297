<script setup lang="ts">
import type { Vehicle, Company} from '@/profile.d'
import type { User } from '@/account.d'

import { ref, computed, watch } from 'vue'
import TtListbox from '@/components/TtListbox.vue'
import { asyncComputed } from '@vueuse/core'

import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/store/profile'
import { useAccountStore } from '@/store/account'
import { useI18n } from 'vue-i18n'

const account = useAccountStore()
const { t } = useI18n({ useScope: 'global' })

const { user } = storeToRefs(useAccountStore())
const profileStore = useProfileStore()
const { getCompanies } = profileStore

const {
  currentCompany,
  currentVehicle
} = storeToRefs(profileStore)

// These refs are for storing the values of the inputs, because the state not be updated until the user clicks save
const currentCompanyEdit = ref<Company | null>(currentCompany.value)
const currentVehicleEdit = ref<Vehicle | null>(currentVehicle.value)

// Vehicles
const availableVehicles = computed<Vehicle[]>(() => {
  return currentCompanyEdit.value?.vehicles || []
})

const selectedVehicle = computed<Vehicle>({
  get: () => {
    return (
      availableVehicles.value.find(
        (v) => v.id === currentVehicleEdit.value?.id
      ) || availableVehicles.value[0]
    )
  },
  set: (val) => {
    currentVehicleEdit.value = val
  }
})

// Companies
const availableCompanies = asyncComputed<Company[]>(async () => {
  const employers = [
    {
      id: '',
      name: t('profile.noEmployer'),
      org_id: '',
      vehicles: [] as Vehicle[],
      users: [] as User[]
    }
  ]
  if (!user.value) return employers
  const connectedEmployers = await getCompanies(user.value.id)
  return employers.concat(connectedEmployers)
}, [])

const selectedCompany = computed<Company>({
  get: () => {
    return (
      availableCompanies.value.find(
        (c) => c.id === currentCompanyEdit.value?.id
      ) || availableCompanies.value[0]
    )
  },
  set: (val) => {
    currentCompanyEdit.value = val
  }
})

defineProps({
  showEmployer: {
    type: Boolean,
    default: true, // Default to showing content
  },
});

</script>

<template>
  <div class="flex w-full flex-col items-center">
    <span v-if="user?.id" class="flex items-center space-x-1">
      <LogoIconDark class="block h-4 w-4" />
      <span class="text-sm font-semibold tracking-tighter">
        Trusted Trucker #{{ user?.id }}
      </span>
    </span>
    <div v-else class="mb-8 w-full text-center">
      <button
        class="mb-4 flex w-full flex-shrink-0 items-center justify-center rounded border border-tt-gray/25 py-4 text-lg font-semibold leading-4.5 tracking-[-0.43px] text-tt-gray"
      >
        <LogoIconDark class="mr-2 block h-4 w-4" />
        <span>
          {{ t('profile.becomeATrustedTrucker') }}
          <span class="font-light">(23 kr/mån)</span>
        </span>
      </button>
      <router-link to="/" class="light text-tt-blue underline">
        {{ t('profile.trustedTruckerReadMore') }}
      </router-link>
    </div>
    <span class="block text-tt-gray">
      <router-link
        v-if="!user?.verified"
        :to="{ name: 'verify-profile' }"
        class="underline"
      >
        {{ t('profile.nonVerified') }}
      </router-link>
      <template v-else>
        {{ t('profile.verified') }}
      </template>
    </span>
    <h1 class="-mb-1 mt-2 text-3xl font-semibold tracking-tighter text-black">
      {{ user?.firstname }} {{ user?.lastname || '' }}
    </h1>
    <span class="font-light tracking-tight text-tt-gray">
      <!-- {{ currentCompany?.name || t('profile.noEmployer')}} -->
      <div class="relative mt-2" v-if="showEmployer && !(account.hasRole('siteowner') || account.hasRole('companyadmin'))">
            <TtListbox
              :dark="true"
              :options="availableCompanies"
              :defaultValue="selectedCompany"
              :optionDisplay="'name'"
              :disabled="availableCompanies.length === 1"
              @update="
                (val) => {
                  selectedCompany = val
                  selectedVehicle = selectedCompany.vehicles[0]
                }
              "
            >
              <template #option="option">
                {{ option.option.name
                }}<span v-if="option.option.org_id" class="flex-1 text-right">
                  ({{ option.option.org_id }})</span
                >
              </template>
              <div
                v-if="selectedCompany"
                class="flex items-center justify-between"
              >
                <div>
                  {{ selectedCompany.name
                  }}<span v-if="selectedCompany.org_id">
                    ({{ selectedCompany.org_id }})</span
                  >
                </div>
              </div>
              <div v-else>
                <span>{{ t('profile.noEmployer') }}</span>
              </div>
            </TtListbox>
          </div>
      <template
        v-if="
          currentCompany?.id && currentVehicle?.id && currentVehicle?.reg_number
        "
        >, {{ currentVehicle.reg_number }}
      </template>
    </span>
  </div>
</template>

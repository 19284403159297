<script lang="ts" setup>
import { ref, computed } from 'vue'
import { humanReadableNumber } from '@/helpers'
import TtFilterCount from '@/components/TtFilterCount.vue'

const props = defineProps<{
  number?: number
  inactive?: boolean
  modelValue?: boolean
}>()

const value = ref(false)

const checked = computed({
  get: () => props.modelValue ?? value.value,
  set: (v) => {
    value.value = v
    emit('change', v)
    emit('update:modelValue', v)
  }
})

const count = computed(() => {
  if (props.number === undefined) return ''
  return humanReadableNumber(props.number)
})

const emit = defineEmits<{
  (event: 'change', value: boolean): void
  (event: 'update:modelValue', value: boolean): void
}>()
</script>

<template>
  <label
    class="relative -mx-3 flex cursor-pointer items-center justify-between overflow-hidden rounded px-3 py-3.25 transition-shadow hover:ring-1 hover:ring-tt-pale/25"
  >
    <input v-model="checked" class="peer sr-only" type="checkbox" />
    <div
      class="relative z-10 flex flex-1 items-center justify-evenly font-light peer-checked:text-tt-gray"
    >
      <div class="flex-1">
        <slot />
      </div>
      <div class="flex">
        <div class="absolute right-14 h-6 w-6 items-center justify-center">
          <slot name="icon" />
        </div>
        <div
          v-if="number !== undefined"
          class="relative flex items-center justify-center font-medium transition-colors rounded-sm border border-white/20"
        >
          <TtFilterCount :count="count" :disabled="!!inactive" :checked="checked" />
        </div>
      </div>
    </div>
    <div
      aria-hidden="true"
      class="absolute inset-0 z-0 transition-colors peer-checked:bg-tt-pale"
    ></div>
  </label>
</template>

<script setup lang="ts">
import type { Location } from '@/location'
import type { Place } from '@/maps'
import { computed, ref, watch, nextTick } from 'vue'
import { useAccountStore } from '@/store/account'
import { useLocationStore } from '@/store/location'
import { useMapsStore } from '@/store/maps'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useSidebarStore } from '@/store/sidebars'
import { formattedAddress } from '@/utils/location'
import { useNotificationsStore } from '@/store/notifications'

import IconStar from '../Icon/Star.vue'
import TtButton from '../TtButton.vue'
import LocationHeader from './LocationHeader.vue'
import LocationForm from './LocationForm.vue'
import Icon from '@/components/Icon.vue'
import IconFavLined from '@/components/Icon/FavLined.vue'
import IconFavFilled from '@/components/Icon/FavFilled.vue'

const { openSidebar } = useSidebarStore()

const { t } = useI18n({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const locations = useLocationStore()
const maps = useMapsStore()
const account = useAccountStore()
const notificationsStore = useNotificationsStore()

const { location } = storeToRefs(locations)
const { currentLocation } = storeToRefs(maps)
const { user, favorites } = storeToRefs(account)

const addToRoute = () => {
  maps.addLocationToRoute(location.value as Location)
  router.push('/route/new')
}

watch(
  () => route.params.id,
  (id) => {
    if (Array.isArray(id)) {
      id = id[0]
    }
    locations.setLocation(id)
    openSidebar()
  },
  { immediate: true }
)

const changeLocation = (place: Place) => {
  if (place) {
    location.value = {
      id: '',
      name: place.place_name,
      address: {
        street: '',
        city: '',
        zip: '',
        country: ''
      },
      coordinates: {
        lat: place.center[1],
        lng: place.center[0]
      },
      meta: []
    }
    currentLocation.value = place.center
  }
}

const editLocation = () => {
  locations.flyToLocation(location.value as Location, 18)
  router.push('/location/' + location.value?.id + '/edit')
}

const isFavorite = ref(false)
watch(
  () => location.value?.id,
  (id) => {
    console.log('location id', id)
    if (id) {
      isFavorite.value =
        location.value?.meta?.some(
          (meta) =>
            meta.type === 'user_favorite' &&
            typeof meta.value !== 'string' &&
            meta.value.key === 'true'
        ) ?? false
    }
  },
  { immediate: true }
)

const timeouts = new Map()
const favorite = async () => {
  if (
    location.value?.id &&
    (await locations.toggleFavorite(location.value?.id, !isFavorite.value))
  ) {
    isFavorite.value = !isFavorite.value
    nextTick(() => {
      if (isFavorite.value === false) {
        notificationsStore.addNotification(
          'remove-favorite',
          t('location.removeFavorite'),
          'notification',
          false,
          false,
          false,
          false
        )
        clearTimeout(timeouts.get('remove-favorite'))
        timeouts.set(
          'remove-favorite',
          setTimeout(() => {
            notificationsStore.removeNotification('remove-favorite')
          }, 2000)
        )
      } else {
        notificationsStore.addNotification(
          'add-favorite',
          t('location.saveFavorite'),
          'notification',
          false,
          false,
          false,
          false
        )
        clearTimeout(timeouts.get('add-favorite'))
        timeouts.set(
          'add-favorite',
          setTimeout(() => {
            notificationsStore.removeNotification('add-favorite')
          }, 2000)
        )
      }
    })
  }
}
</script>

<template>
  <div v-if="location" class="flex h-full flex-col">
    <div class="">
      <LocationHeader :location="location" />
      <div class="mt-5.5">
        <div v-if="location.id && route.name === 'location'">
          <div class="flex items-start justify-between gap-1.5 px-5">
            <div class="flex items-start gap-1.5">
              <Icon
                v-if="location.assets"
                :location="location"
                :size="5"
                :priority="['icon-card']"
                class="mb-1 mr-1.5 mt-1 inline-block align-middle"
              />
              <h2 class="text-2xl font-semibold">
                <template v-if="location?.name">
                  {{ location?.name }}
                </template>
                <template v-else>
                  {{ formattedAddress(location) }}
                </template>
              </h2>
            </div>
            <button
              class="relative mt-1 h-6 w-6 rounded-full p-1.5"
              @click.prevent="favorite"
            >
              <TransitionGroup name="heart" tag="div">
                <IconFavLined
                  v-if="!isFavorite"
                  class="absolute left-0 top-0 h-6 w-6"
                  key="heart1"
                />
                <IconFavFilled
                  v-if="isFavorite"
                  class="absolute left-0 top-0 h-6 w-6 text-tt-red"
                  key="heart2"
                />
              </TransitionGroup>
            </button>
          </div>

          <p v-if="formattedAddress(location)" class="px-5 pt-1 font-light">
            {{ formattedAddress(location) }}
          </p>
          <div class="mt-3 flex items-center gap-2 px-5">
            <IconStar class="text-tt-green" />
            <div class="flex items-baseline gap-1">
              <span class="font-medium">{{ location?.rating ?? 0 }}</span>
              <template v-if="location?.review_count">
                <span class="relative -top-px text-sm opacity-30">&bull;</span>
                <span class="font-light">
                  {{ location?.review_count ?? 0 }} {{ $t('location.reviews') }}
                </span>
              </template>
            </div>
          </div>
          <div class="mt-4 flex gap-2.5 px-5"
          v-if="!account.hasRole('siteowner')"
          >
            <TtButton
              :as="'button'"
              :size="'small'"
              :type="'primary'"
              class="!flex-grow px-8 tracking-tighter"
              @click.prevent="addToRoute()"
            >
              {{ t('filter.places.add_location_to_route') }}
            </TtButton>
          </div>
          <div
            v-if="location.user && user && location.user.id === user.id"
            class="mt-2.5 flex gap-2.5 px-5"
          >
            <TtButton
              :as="'button'"
              :size="'large'"
              :type="'tertiary'"
              class=""
              @click.prevent="editLocation()"
            >
              {{ t('location.editLocation') }}
            </TtButton>
          </div>
          <div class="mt-2.5 flex gap-2.5 px-5"
          v-if="!account.hasRole('siteowner')"
          >
            <TtButton
              :as="'button'"
              :size="'large'"
              :type="'tertiary'"
              class=""
              @click.prevent="addToRoute()"
            >
              {{ t('filter.places.check_in_on_site') }}
            </TtButton>
          </div>
          <div class="mt-2.5 flex gap-2.5 px-5">
            <TtButton
              :as="'button'"
              :size="'large'"
              :type="'tertiary'"
              @click.prevent="addToRoute()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.75"
                height="21"
                viewBox="0 0 15.75 21"
              >
                <path
                  fill="#374650"
                  fill-rule="nonzero"
                  d="M7.875 1.313c-.915 0-1.69.627-1.907 1.476a.654.654 0 0 1-.636.492h-.738a.658.658 0 0 0-.657.656V5.25h7.876V3.937a.658.658 0 0 0-.657-.656h-.738a.654.654 0 0 1-.636-.492 1.968 1.968 0 0 0-1.907-1.477Zm-3.006.656a3.281 3.281 0 0 1 6.012 0h.275c.857 0 1.588.55 1.858 1.312h.111a2.627 2.627 0 0 1 2.625 2.625v12.469A2.627 2.627 0 0 1 13.125 21h-10.5A2.627 2.627 0 0 1 0 18.375V5.906a2.627 2.627 0 0 1 2.625-2.625h.11c.271-.763 1.002-1.312 1.859-1.312h.275Zm8.256 3.281c0 .726-.586 1.313-1.313 1.313H3.938A1.311 1.311 0 0 1 2.625 5.25v-.656c-.726 0-1.313.586-1.313 1.312v12.469c0 .726.587 1.313 1.313 1.313h10.5c.726 0 1.313-.587 1.313-1.313V5.906c0-.726-.587-1.312-1.313-1.312v.656Zm-5.25-2.625a.656.656 0 1 1 0 1.313.656.656 0 0 1 0-1.313Z"
                />
              </svg>
              <span class="pl-2">{{
                t('filter.places.share_secure_link_address_to_the_site')
              }}</span>
            </TtButton>
          </div>
        </div>
        <div class="mx-5" v-else>
          <LocationForm :location="location" @changeLocation="changeLocation" />
        </div>
      </div>
    </div>
    <slot name="body"></slot>
  </div>
</template>
<style scoped>
/* heart animation */
.heart-enter-active,
.heart-leave-active {
  transition: opacity 0.2s;
}
.heart-enter,
.heart-leave-to {
  opacity: 0;
}

.heart-enter-active {
  animation: heart 0.2s;
}
@keyframes heart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* .heart-leave-active {
  animation: heart 0.2s reverse;
} */
</style>

<script setup lang="ts">
import type { Vehicle, Company, VehicleType, FuelType } from '@/profile.d'
import type { User } from '@/account.d'

import { ref, computed, watch } from 'vue'
import TtContainer from '@/components/TtContainer.vue'
import TtButton from '@/components/TtButton.vue'
import TtListbox from '@/components/TtListbox.vue'
import TtInput from '@/components/TtInput.vue'
import TtPhoneInput from '@/components/TtPhoneInput.vue'
import ProfilePicture from '@/components/Icon/ProfilePicture.vue'
import IconClose from '@/components/Icon/Close.vue'
import LogoFullLight from '@/components/Logo/LogoFullLight.vue'
import ProfileSubHead from '@/components/ProfileSubHead.vue'
import { asyncComputed } from '@vueuse/core'
import Icon from '@/components/Icon.vue'

import axios from '@/axios'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/store/profile'
import { useAccountStore } from '@/store/account'
import { useMetaFilterStore } from '@/store/metafilter'
import { useI18n } from 'vue-i18n'
import type { FilterOption } from '@/filters/meta'
import { useRouter } from 'vue-router'

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/user'
})

const router = useRouter()

const { t } = useI18n({ useScope: 'global' })

const { refreshUser } = useAccountStore()
const { user, firebase_id } = storeToRefs(useAccountStore())
const { filter } = storeToRefs(useMetaFilterStore())
const profileStore = useProfileStore()
const { getCompanies } = profileStore

const currentEmail = ref(user.value?.email)
const currentPhone = ref(user.value?.phone)

const {
  currentCompany,
  currentVehicle,
  currentType,
  uploadCount,
  totalHeight,
  totalWeight,
  totalLength,
  totalWidth,
  averageConsumption,
  fuelType,
  totalDrivingTime
} = storeToRefs(profileStore)

const currentEmailEdit = ref(currentEmail.value)
const currentPhoneEdit = ref(currentPhone.value)

// These refs are for storing the values of the inputs, because the state not be updated until the user clicks save
const currentCompanyEdit = ref<Company | null>(currentCompany.value)
const currentVehicleEdit = ref<Vehicle | null>(currentVehicle.value)
const currentTypeEdit = ref<VehicleType | null>(currentType.value)
const totalHeightEdit = ref<Number | undefined>(totalHeight.value)
const totalWeightEdit = ref<Number | undefined>(totalWeight.value)
const totalLengthEdit = ref<Number | undefined>(totalLength.value)
const totalWidthEdit = ref<Number | undefined>(totalWidth.value)
const averageConsumptionEdit = ref<Number | undefined>(averageConsumption.value)
const fuelTypeEdit = ref<FuelType | null>(fuelType.value)
const totalDrivingTimeEdit = ref<String | undefined>(totalDrivingTime.value)

// Vehicles
const availableVehicles = computed<Vehicle[]>(() => {
  return currentCompanyEdit.value?.vehicles || []
})

const vehicleTypes = [
  { id: 1, name: 'Dragbil + semi-trailer' },
  { id: 2, name: 'Dragbil + trailer + dolly + trailer (duo-trailer)' },
  { id: 3, name: 'Dragbil + link och trailer' },
  { id: 4, name: 'Jämnlastbil + släp' },
  { id: 5, name: 'Jämnlastbil + dolly + trailer' }
] as VehicleType[]

const lengths = [
  { id: 15, name: '<15 m' },
  { id: 22, name: '<22 m' },
  { id: 24, name: '<24 m' },
  { id: 34, name: '<34 m' }
] as {
  id: number
  name: string
}[]


const selectedVehicle = computed<Vehicle>({
  get: () => {
    return (
      availableVehicles.value.find(
        (v) => v.id === currentVehicleEdit.value?.id
      ) || availableVehicles.value[0]
    )
  },
  set: (val) => {
    currentVehicleEdit.value = val
  }
})

// Companies
const availableCompanies = asyncComputed<Company[]>(async () => {
  const employers = [
    {
      id: '',
      name: t('profile.noEmployer'),
      org_id: '',
      vehicles: [] as Vehicle[],
      users: [] as User[]
    }
  ]
  if (!user.value) return employers
  const connectedEmployers = await getCompanies(user.value.id)
  return employers.concat(connectedEmployers)
}, [])

const selectedCompany = computed<Company>({
  get: () => {
    return (
      availableCompanies.value.find(
        (c) => c.id === currentCompanyEdit.value?.id
      ) || availableCompanies.value[0]
    )
  },
  set: (val) => {
    currentCompanyEdit.value = val
  }
})

// Fuel filter
const fuelFilter =
  filter.value && filter.value.sections
    ? filter.value.sections.find((s) => s.label === 'Drivmedel')
    : null
const fuelTypes = fuelFilter
  ? (Object.values(fuelFilter.options?.fuels.children || []).map(
      (o: FilterOption) => ({
        id: o.key,
        name: o.label,
        icon: o.icon,
        unit: o.key.startsWith('electric') ? 'kWh' : 'l'
      })
    ) as FuelType[])
  : []

if (fuelTypes.length > 0 && fuelType.value === null) {
  fuelType.value = fuelTypes[0]
}

if (currentTypeEdit.value === null) {
  currentTypeEdit.value = vehicleTypes[0]
}

const file = ref<File | null>()
const fileInput = ref<HTMLInputElement | null>(null)
const onFileChanged = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target.files) {
    file.value = target.files[0]
  }

  sendImage()
}

const sendImage = async () => {
  if (file.value && user.value?.id) {
    await profileStore.uploadImage(file.value, user.value.id)
    file.value = null
    if (fileInput.value) {
      fileInput.value.value = ''
    }
  }
}

function trimPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\s0\d+/g, match => " "+Number(match).toString())
}

const save = async () => {
  if (currentEmail != currentEmailEdit || currentPhone != currentPhoneEdit) {
    currentEmail.value = currentEmailEdit.value
    currentPhone.value = trimPhoneNumber(currentPhoneEdit.value || '')
    console.log(currentPhone.value)

    await instance
      .post(`/${user.value?.id}/update`, {
        email: currentEmail.value,
        phone: currentPhone.value
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })


  }
  
  currentCompany.value = currentCompanyEdit.value
  currentVehicle.value = currentVehicleEdit.value
  currentType.value = currentTypeEdit.value
  totalHeight.value = totalHeightEdit.value
  totalWeight.value = totalWeightEdit.value
  totalLength.value = totalLengthEdit.value
  totalWidth.value = totalWidthEdit.value
  averageConsumption.value = averageConsumptionEdit.value
  fuelType.value = fuelTypeEdit.value || fuelTypes[0]
  totalDrivingTime.value = totalDrivingTimeEdit.value

  await refreshUser()

}

watch(currentVehicleEdit, () => {
  if (!currentVehicleEdit.value) return
  currentTypeEdit.value =
    vehicleTypes.find(
      (t) => t.id === currentVehicleEdit.value?.vehicleType?.id
    ) || vehicleTypes[0]
  totalHeightEdit.value = currentVehicleEdit.value.totalHeight || 4.3
  totalWeightEdit.value = currentVehicleEdit.value.totalWeight || 3.5
  totalLengthEdit.value = currentVehicleEdit.value.totalLength || 15
  totalWidthEdit.value = currentVehicleEdit.value.totalWidth || 2.6
  averageConsumptionEdit.value =
    currentVehicleEdit.value.averageConsumption || 3.3
  fuelTypeEdit.value = currentVehicleEdit.value.fuelType || fuelTypes[0]
})

watch(
  [
    currentTypeEdit,
    totalHeightEdit,
    totalWeightEdit,
    totalLengthEdit,
    totalWidthEdit,
    averageConsumptionEdit,
    fuelTypeEdit
  ],
  () => {
    if (!currentVehicleEdit.value) return
    const vehicle = availableVehicles.value.find(
      (v) => v.id === currentVehicleEdit.value?.id
    )
    if (!vehicle) return
    vehicle.vehicleType = currentTypeEdit.value
    vehicle.totalHeight = totalHeightEdit.value
    vehicle.totalWeight = totalWeightEdit.value
    vehicle.totalLength = totalLengthEdit.value
    vehicle.totalWidth = totalWidthEdit.value
    vehicle.averageConsumption = averageConsumptionEdit.value
    vehicle.fuelType = fuelTypeEdit.value || fuelTypes[0]
  }
)
watch(totalHeightEdit, () => {
  if (totalHeightEdit.value === undefined) {
    totalHeightEdit.value = 4.3
    return
  }
  totalHeightEdit.value = Math.min(
    Math.max(totalHeightEdit.value as number, 1),
    10
  )
})
watch(totalWeightEdit, () => {
  if (totalWeightEdit.value === undefined) {
    totalWeightEdit.value = 3.5
    return
  }
  totalWeightEdit.value = Math.min(
    Math.max(totalWeightEdit.value as number, 1),
    64
  )
})
watch(totalWidthEdit, () => {
  if (totalWidthEdit.value === undefined) {
    totalWidthEdit.value = 2.6
    return
  }
  totalWidthEdit.value = Math.min(
    Math.max(totalWidthEdit.value as number, 1),
    3.1
  )
})
refreshUser()
</script>

<template>
  <div>
    <TtContainer
      @set-open="
        (val) => {
          val === false && router.push('/')
        }
      "
      :open="true"
      variant="popup-card-offset"
      class="z-40"
    >
      <template #offset>
        <div>
          <LogoFullLight class="mx-auto h-6" />
          <button class="absolute right-6 top-1" @click="router.push('/')">
            <IconClose class="h-3.5 w-3.5 text-white" />
          </button>
        </div>
      </template>
      <template #header>
        <div class="-mt-[4.75rem]">
          <ProfilePicture
            :key="uploadCount"
            size="141x141"
            class="mx-auto block h-[141px] w-[141px] cursor-pointer rounded-full object-cover"
            @click="() => fileInput?.click()"
          />

          <input
            type="file"
            @change="onFileChanged($event)"
            ref="fileInput"
            accept="image/*"
            class="hidden"
            capture
          />
        </div>
        <div
          aria-hidden="true"
          class="absolute -bottom-6 left-0 z-10 block w-full px-1.5 pb-4 pt-2"
        >
          <div
            class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(white_70%,transparent)]"
          ></div>
          <div
            class="absolute inset-0 z-0 bg-gradient-to-b from-white from-40% to-[transparent]"
          ></div>
        </div>
      </template>
      <div class="mt-6 px-5 pb-10">
        <ProfileSubHead :showEmployer="false" />
        <div class="relative mt-5.5">
          <h2 class="text-lg font-medium">{{ t('profile.info') }}</h2>
          <TtInput
            :dark="true"
            id="name"
            type="text"
            class="mt-2"
            v-model="currentEmailEdit"
            >{{ t('account.email') }}
          </TtInput>
          <TtPhoneInput
          :dark="true"
          class="mt-2"
          id="phone"
          v-model="currentPhoneEdit"
          > {{ t('account.phone') }} 
          </TtPhoneInput>
          <!-- <h2 class="mt-5.5 text-lg font-medium">{{ t('profile.employer') }}</h2>
          <div class="relative mt-2">
            <TtListbox
              :dark="true"
              :options="availableCompanies"
              :defaultValue="selectedCompany"
              :optionDisplay="'name'"
              :disabled="availableCompanies.length === 1"
              @update="
                (val) => {
                  selectedCompany = val
                  selectedVehicle = selectedCompany.vehicles[0]
                }
              "
            >
              <template #option="option">
                {{ option.option.name
                }}<span v-if="option.option.org_id" class="flex-1 text-right">
                  ({{ option.option.org_id }})</span
                >
              </template>
              <div
                v-if="selectedCompany"
                class="flex items-center justify-between"
              >
                <div>
                  {{ selectedCompany.name
                  }}<span v-if="selectedCompany.org_id">
                    ({{ selectedCompany.org_id }})</span
                  >
                </div>
              </div>
              <div v-else>
                <span>{{ t('profile.noEmployer') }}</span>
              </div>
            </TtListbox>
          </div> -->
        </div>
        <div class="relative mt-7">
          <div>
            <div class="flex items-center justify-between">
              <h2 class="text-lg font-medium">{{ t('profile.carriage') }}</h2>
              <span
                v-if="currentCompanyEdit?.id"
                class="text-base font-light text-gray-700"
                >{{ currentCompanyEdit?.name }}</span
              >
            </div>
            <div class="mt-3">
              <TtListbox
                v-if="currentCompanyEdit?.id && availableVehicles.length > 0"
                :dark="true"
                :options="availableVehicles"
                :defaultValue="selectedVehicle"
                :optionDisplay="['name']"
                @update="(val) => (selectedVehicle = val)"
              >
                <template #option="option">
                  {{ option.option.name
                  }}<span v-if="option.option.reg_number"
                    >, {{ option.option.reg_number }}</span
                  >
                </template>
                <div
                  v-if="selectedVehicle"
                  class="flex items-center justify-between"
                >
                  <div>
                    {{ selectedVehicle.name }}, {{ selectedVehicle.reg_number }}
                  </div>
                  <div class="flex items-center space-x-2">
                    <span
                      class="rounded-sm border border-white/20 px-2 py-0 font-medium"
                    >
                      {{ availableVehicles.length }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ t('profile.noVehicle') }}</span>
                </div>
              </TtListbox>
            </div>
          </div>
          <div class="relative mt-3">
            <TtListbox
              :dark="true"
              :options="vehicleTypes"
              :defaultValue="currentTypeEdit"
              :optionDisplay="'name'"
              @update="(val) => (currentTypeEdit = val)"
            >
              <div>
                {{ currentTypeEdit?.name }}
              </div>
            </TtListbox>
          </div>
          <div class="mt-3 grid grid-cols-2 items-center gap-3">
            <div>
              <TtInput
                :dark="true"
                id="totalWeight"
                type="number"
                v-model="totalWeightEdit"
                >{{ t('profile.totalWeight') }}</TtInput
              >
            </div>
            <div class="relative">
              <TtListbox
                :options="lengths"
                :defaultValue="
                  lengths.filter((length) => {
                    length.id === totalLengthEdit
                  })
                "
                :optionDisplay="'name'"
                :dark="true"
                @update="(val) => (totalLengthEdit = val.id)"
              >
                <template #label>
                  {{ t('profile.totalLength') }}
                </template>
                <div class="-ml-2 flex items-center gap-1">
                  <div>
                    {{
                      !Number.isInteger(totalLengthEdit)
                        ? t('profile.nolLength')
                        : `<${totalLengthEdit} m`
                    }}
                  </div>
                </div>
              </TtListbox>
            </div>
            <div>
              <TtInput
                :dark="true"
                id="totalHeight"
                type="number"
                v-model="totalHeightEdit"
                >{{ t('profile.totalHeight') }}</TtInput
              >
            </div>
            <div>
              <TtInput
                :dark="true"
                id="totalWidth"
                type="number"
                v-model="totalWidthEdit"
                >{{ t('profile.totalWidth') }}</TtInput
              >
            </div>
            <div>
              <TtListbox
                :options="fuelTypes"
                :defaultValue="fuelTypeEdit"
                :optionDisplay="'name'"
                :dark="true"
                @update="(val) => (fuelTypeEdit = val)"
              >
                <template #label>
                  {{ t('profile.fuel') }}
                </template>
                <div v-if="fuelTypeEdit" class="-ml-2 flex items-center gap-1">
                  <template v-if="fuelTypeEdit.icon">
                    <Icon
                      :assets="fuelTypeEdit.icon"
                      :priority="['icon-map']"
                      :size="4"
                    />
                  </template>
                  <div>
                    {{ fuelTypeEdit.name }}
                  </div>
                </div>
                <div v-else class="-ml-2 flex items-center gap-1">
                  {{ t('profile.noFuel') }}
                </div>
              </TtListbox>
            </div>
            <div>
              <TtInput
                :dark="true"
                id="consumption"
                type="number"
                v-model="averageConsumptionEdit"
              >
                {{ t('profile.consumption') }}
                <template #icon>
                  <svg
                    v-if="
                      fuelTypeEdit?.id === 'electric_renewable' ||
                      fuelTypeEdit?.id === 'electric_nuclear' ||
                      fuelTypeEdit?.id === 'electric_fossil'
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill="none"
                      fill-rule="evenodd"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 1 4 7h6l-4 6"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="nonzero"
                      d="M7 .875c.484 0 .875.391.875.875v3.5H1.75v-3.5c0-.484.391-.875.875-.875H7Zm.875 5.25v7H1.75v-7h6.125Zm-7-4.375v11.375H.437a.439.439 0 0 0-.437.438c0 .24.197.437.438.437H9.188a.439.439 0 0 0 0-.875H8.75v-5.25h.438c.724 0 1.312.588 1.312 1.313V10.5c0 .965.785 1.75 1.75 1.75S14 11.465 14 10.5V4.296c0-.49-.205-.96-.569-1.29L11.233.99a.437.437 0 1 0-.59.645l.732.673v2.067c0 .965.785 1.75 1.75 1.75V10.5a.874.874 0 1 1-1.75 0V9.187A2.187 2.187 0 0 0 9.187 7H8.75V1.75C8.75.785 7.965 0 7 0H2.625C1.66 0 .875.785.875 1.75Zm12.25 3.5a.874.874 0 0 1-.875-.875V3.109l.59.541c.181.167.285.4.285.646v.954Z"
                    />
                  </svg>
                </template>
              </TtInput>
            </div>
            <!-- <div>
              <TtInput
                :dark="true"
                id="totalDrivingTime"
                type="time"
                v-model="totalDrivingTimeEdit"
                >{{ t('profile.totalDrivingTime') }}</TtInput
              >
            </div> -->
          </div>
        </div>
        <div class="relative mt-8">
          <div>
            <h2 class="text-lg font-medium">Tank och bonuskort</h2>
            <div class="mt-3">
              <input
                type="text"
                class="form-input relative flex w-full items-center space-x-2 rounded border border-tt-gray/25 bg-transparent px-3 py-4 text-base font-light leading-4.5 text-tt-gray transition-colors placeholder:text-white focus:border-tt-green focus:outline-none focus:ring-0"
              />
            </div>
          </div>
        </div>
        <div class="mt-12 grid gap-3">
          <TtButton
            :type="'primary'"
            :size="'large'"
            as="button"
            @click="save"
            :to="{
              name: 'profile'
            }"
          >
            {{ t('profile.save') }}
          </TtButton>
          <TtButton
            :type="'tertiary'"
            :size="'large'"
            as="router-link"
            :to="{
              name: 'profile'
            }"
          >
            {{ t('profile.cancel') }}
          </TtButton>
          <div v-if="user?.id" class="mt-8 text-center">
            <router-link to="/" class="font-medium text-tt-red underline">
              {{ t('profile.terminateAccount') }}
            </router-link>
          </div>
        </div>
      </div>
    </TtContainer>
  </div>
</template>
